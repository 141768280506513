<template>
  <v-menu offset-y dense>
    <template v-slot:activator="{ on, attrs }">
      <v-btn x-small fab depressed v-bind="attrs" v-on="on">
        <v-icon color="grey" size="18">mdi-timeline-help</v-icon>
      </v-btn>
    </template>

    <v-list dense class="text-caption">
      <v-list-item-group>
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          :to="{ name: item.name }"
          exact-path
          :inactive="item.disabled"
        >
          <v-list-item-icon class="mx-0 px-0">
            <v-icon size="18" color="grey lighten-1">mdi-check-circle-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            {{ item.text }}
          </v-list-item-title>
        </v-list-item>

        <v-list-item exact-path inactive>
          <v-list-item-icon class="mx-0 px-0">
            <v-icon size="18" color="primary">mdi-check-circle</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            {{ this.$store.state.currentTitle }}
          </v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  data: () => ({
    items: [],
  }),
  watch: {
    $route: {
      immediate: true,
      handler() {
        const routes = this.$router.matcher.getRoutes();
        const pathNames = this.$route.meta.parentPathNames || [];
        const breadcrumbItems = [{ text: "Panel de control", name: "dashboard" }];

        for (const name of pathNames) {
          const route = routes.find(r => r.name === name);
          if (route) {
            const routeMeta = route.meta;
            const item = {
              text: routeMeta.title,
              name: name,
              disabled: routeMeta.breadcrumbDisabled,
            };

            breadcrumbItems.push(item);
          }
        }

        this.items = breadcrumbItems;
      },
    },
  },
};
</script>

<style scoped></style>
